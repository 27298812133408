/**
 * Summary: Get is insurance enabled flag.
 *
 * Tags: Insurance
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_INSURANCE_IS_INSURANCE_ENABLED_URL = "api/v1/insurance/is-insurance-enabled";

export const apiGetInsuranceIsInsuranceEnabled = async (
    config?: AxiosRequestConfig
): Promise<ApiGetInsuranceIsInsuranceEnabledResponse> => {
    const result = await httpGet<ApiGetInsuranceIsInsuranceEnabledResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_INSURANCE_IS_INSURANCE_ENABLED_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetInsuranceIsInsuranceEnabledResponse = GetIsInsuranceEnabledFlagResponse;

interface GetIsInsuranceEnabledFlagResponse {
    isInsuranceEnabledForCurrentUser: boolean;
}
