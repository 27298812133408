export enum LocalizedComponentName {
    AccessErrorPage = "error-403",
    AccountDeactivate = "account-deactivate",
    AccountLink = "account-link",
    AccountMerge = "account-merge",
    AccountPartner = "account-partner",
    AccountReactivate = "account-reactivate",
    AccountStatuses = "account-statuses",
    AccountTypes = "account-types",
    AccountUserStatuses = "account-user-statuses",
    Activity = "activity",
    ActivityReleaseRequest = "release-request-activity",
    AddPropertyAgencyForm = "add-property-agency-form",
    AddressForm = "address-form",
    AddTenancy = "add-tenancy",
    AdminAddProperty = "adm-add-property",
    AdminBusiness = "business-admin",
    AdminCreateUser = "adm-create-user",
    AdminFinanceCharityGrid = "adm-finance-charity-grid",
    AdminFinanceSettings = "adm-finance-setting",
    AdminFinanceFailedPayment = "adm-finance-failed-payment",
    AdminFinanceVouchers = "finance-voucher-admin",
    AdminSettingsInternalUsers = "settings-internal-user-admin",
    AdminSettingsInvitation = "adm-settings-invitation",
    AdminSettingsPartners = "settings-partner-admin",
    AdminSettingsPartnerProfile = "settings-partner-profile-admin",
    AdminSettingsPortal = "adm-settings-portal",
    AdminSettingsRates = "settings-rate-admin",
    AdminUsersList = "adm-users-list",
    AgentAccountFinish = "sign-up-agent-finish",
    AuditActionTypes = "audit-action-types",
    AuditObjectTypes = "audit-object-types",
    AuthorizedContactSection = "profile-authorized-contacts-section",
    BankDetails = "bank-detail",
    BillingBankAccountDetail = "billing-bank-account-detail",
    BillingCardDetail = "billing-card-detail",
    BulkPropertiesUpload = "bulk-properties-upload",
    CertificateCustomisation = "certificate-customisation",
    ChangeOfTenantsSection = "tenancy-change-tenant-view",
    ChangeOfTenantsStatus = "tenancy-tenants-change-request-statuses",
    ChangeOfTenantsType = "tenancy-tenants-change-request-party-types",
    Chat = "chat",
    ClaimMakeDecision = "claim-make-decision",
    ClaimStart = "claim-start",
    ClaimStartAddSettlements = "claim-start-add-settlement",
    ClaimStartViewAgreements = "claim-start-view-agreement",
    CommonDataGrid = "common-data-grid",
    CompanyDetails = "company-detail",
    CompanyDetailsAndAddress = "sign-up-agent-detail",
    CompanyFormDetails = "company-detail-form",
    CompanyOffice = "office",
    CompanyOffices = "office-grid",
    CompanyUserRoles = "company-user-roles",
    ComplaintDetails = "complaint-detail",
    ComplaintReasons = "complaint-reasons",
    ConfirmationExpiredLinkErrorPage = "confirmation-link-expired",
    ContinueSignUpPage = "continue-sign-up-page",
    CorporateLLDetails = "sign-up-corporate-landlord-detail",
    CorporateLLFinish = "sign-up-corporate-landlord-finish",
    Dashboard = "dashboard",
    DeactivationReasons = "deactivation-reasons",
    Deposit = "deposit",
    DepositBasket = "deposit-basket",
    DepositBasketCheckout = "deposit-basket-checkout",
    DepositBasketGroup = "deposit-basket-group",
    DepositExportHistory = "deposit-export-history",
    DepositForm = "deposit-form",
    DepositGrid = "deposit-grid",
    DepositProtectionPaymentForm = "deposit-protection-payment-form",
    DepositProtectionSchemes = "deposit-protection-schemes",
    DepositReplacementPaymentForm = "deposit-replacement-payment-form",
    DepositStatuses = "deposit-statuses",
    DepositUnallocatedFunds = "deposit-unallocated-fund",
    DepositView = "deposit-view",
    EmploymentStatuses = "employment-statuses",
    EndOfTenancyConfirmation = "end-of-tenancy-confirmation",
    ExpiredInviteLink = "expired-invite-link",
    FileLoader = "file-loader",
    FooterLabelsLinks = "footer-labels-links",
    Gallery = "gallery",
    HonestyQuestions = "honesty-questions-links",
    InProgressTab = "in-progress-view",
    IntercomChat = "intercom-chat",
    Insurance = "insurance",
    InsuranceGetQuote = "insurance-get-quote",
    InsuranceLandlordTab = "insurance-landlord-tab",
    InterestedPartiesSection = "interested-parties-section",
    InterestedPartiesSectionForm = "interested-parties-section-form",
    InternalErrorPage = "error-500",
    InternalUserFinish = "sign-up-internal-user-finish",
    InviteRevoked = "invite-revoked",
    LandlordAddProperty = "landlord-add-property",
    LandlordProperties = "landlord-property",
    LandlordsGrid = "landlords-grid",
    LandlordsView = "landlords-view",
    Membership = "memberships",
    MissingDetails = "missing-detail",
    NavMenu = "nav-menu",
    NoRecordsScreen = "no-records-screen",
    NotesAdd = "notes-add",
    NotesView = "notes-view",
    NotFoundErrorPage = "error-404",
    Notifications = "notifications",
    NotificationsModal = "notifications-modal",
    OfficeProperties = "office-property",
    PaymentCard = "payment-card",
    PaymentDescriptions = "payment-descriptions",
    PaymentDirections = "payment-directions",
    PaymentHistoryGrid = "payment-history-grid",
    PaymentMethods = "payment-methods",
    PaymentStatuses = "payment-statuses",
    PermissionGroups = "permission-groups",
    Permissions = "permissions",
    PrivacyPolicyAndTermsAndConditionsConfirmDialog = "privacy-policy-and-terms-and-conditions-confirm-dialog",
    PrivateLLDetails = "sign-up-landlord-details",
    PrivateLLFinish = "sign-up-landlord-finish",
    PropertiesEndOfTenancy = "properties-end-of-tenancy",
    PropertyDetails = "property-detail",
    PropertyStatuses = "property-statuses",
    RaiseComplaintDialog = "raise-complaint-dialog",
    RegistrationExpiredLinkErrorPage = "registration-link-expired",
    RejectedPayment = "rejected-payment",
    ReleaseRequestConfirmBulkInitiation = "release-request-bulk-initiation",
    ReleaseRequestDialog = "release-request-dialog",
    ReleaseRequestEvidencesSection = "release-request-evidences-section",
    ReleaseRequestOverview = "release-request-overview",
    ReleaseRequestResolutionCase = "release-request-resolution-case",
    ReleaseRequestResolutionTimeline = "release-request-resolution-timeline",
    ReleaseRequestSettlementItem = "release-request-settlement-item",
    ReleaseRequestSingleRelease = "release-request-single-release",
    ReleaseRequestStatuses = "release-request-statuses",
    ReleaseRequestUploadEvidencesOnBehalfOfDialog = "upload-evidences-on-behalf-of-dialog",
    RentFrequencies = "rent-frequencies",
    ResolutionManagementClaimDetails = "resolution-management-claim-detail",
    ResolutionManagementClaimsList = "resolution-management-claims-list",
    ResolutionOutcomes = "resolution-outcomes",
    ResolutionPanel = "resolution-panel",
    ResolutionPanelReleaseRequests = "resolution-panel-release-request",
    ResolutionStages = "resolution-stages",
    ReviewStatutoryDeclaration = "release-request-review-statutory-declaration",
    SchemeConfiguration = "scheme-configuration",
    SchemeRegions = "scheme-regions",
    SchemeRules = "scheme-rules",
    SchemeTypes = "scheme-types",
    SettlementForm = "settlement-form",
    SettlementStatuses = "settlement-statuses",
    SettlementTypes = "settlement-types",
    SharedMessages = "shared-messages",
    SignedOut = "signed-out",
    SignInChooseAccount = "sign-in-choose-account",
    SignUpAccountType = "sign-up-account-type",
    SignUpCheckInbox = "sign-up-check-inbox",
    SignUpEnterEmail = "sign-up-enter-email",
    SignUpEnterFirstAndLastName = "sign-up-first-and-last-name",
    SignUpPhoneNumber = "sign-up-phone-number",
    SignUpSmsCode = "sign-up-sms-code",
    SignInPhoneCode = "sign-in-phone-code",
    SingleReleaseInitiation = "single-release-initiation",
    SingleReleaseInitiationClaim = "single-release-initiation-claim-agreement",
    SingleReleaseInitiationConfirmation = "single-release-initiation-confirmation",
    SingleReleaseInitiationMemberInformation = "single-release-initiation-member-information",
    StaffList = "staff-list",
    StaffPage = "staff-page",
    StaffRoles = "staff-role",
    StaffUser = "staff-user",
    StartReleaseRequest = "release-request-initiation",
    StartReleaseRequestAddBankDetails = "release-request-initiation-add-bank-detail",
    StartReleaseRequestAddDocument = "release-request-initiation-add-document",
    StartReleaseRequestAddSettlement = "release-request-initiation-add-settlement",
    StartReleaseRequestInterestRelease = "release-request-initiation-interest-release",
    StartReleaseRequestConfirmation = "release-request-initiation-confirmation",
    StatutoryDeclarationQuestions = "statutory-declaration-questions",
    StatutoryDeclarationStatus = "statutory-declaration-statuses",
    TenanciesGrid = "tenancies-grid",
    TenanciesMenuItem = "tenancies-menu-item",
    TenancyChangeTenant = "tenancy-change-tenant",
    TenancyDetails = "tenancy-detail",
    TenancyDetailsForm = "tenancy-detail-form",
    TenancyHistoricalView = "tenancy-historical-view",
    TenancyRenewal = "tenancy-renewal",
    TenancyStatuses = "tenancy-statuses",
    TenantBilling = "tenant-billing",
    TenantCard = "tenant-card",
    TenantFinish = "sign-up-tenant-finish",
    TenantMyHome = "tenant-my-home",
    TenantsSection = "tenants-section",
    TenantsSectionForm = "tenants-section-form",
    TenantsSectionPaymentCard = "tenants-section-payment-card",
    Transfer = "transfer",
    MidTermRelease = "mid-term-release-initiation",
    MidTermReleaseConfirmation = "mid-term-release-confirmation",
    MidTermReleaseReasons = "mid-term-release-reasons",
    MidTermReleaseCancel = "mid-term-release-cancel",
    NoData = "no-data",
    TransferMethod = "transfer-method",
    TransferMethodBankTransferInstructions = "transfer-method-bank-transfer-instruction",
    TransferMethodChangeMethodDialog = "transfer-method-change-method-dialog",
    UnallocatedFundsRefund = "unallocated-funds-refund",
    UploadDocumentOnBehalfOfDialog = "upload-documents-on-behalf-of-dialog",
    UserActionsToolbar = "profile-actions-toolbar",
    UserProfile = "user-profile",
    UserProfileAbout = "user-profile-about-section",
    UserProfileContacts = "user-profile-contacts-section",
    UserProfileHeadingToolbar = "profile-heading-toolbar",
    UsersInvitation = "users-invitation",
    UserStatuses = "user-statuses",
    ValidationMessages = "validation-messages",
    ViewUserProfileVerificationPopup = "view-user-profile-verification-popup",
    ViewUserProfileVerificationTypes = "view-user-profile-verification-types",
    VouchersUsageMetrics = "vouchers-usage-metric",
    EmailPreferences = "email-preferences",
    EmailPreferencesDescriptions = "email-preferences-descriptions",
}
