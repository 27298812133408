/**
 * Summary: Add current user's insurance quote to audit log.
 *
 * Tags: Insurance
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_INSURANCE_URL = "api/v1/insurance";

export const apiPutInsurance = async (config?: AxiosRequestConfig): Promise<ApiPutInsuranceResponse> => {
    const result = await httpPut<ApiPutInsuranceResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_PUT_INSURANCE_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiPutInsuranceResponse = void;
