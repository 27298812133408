import { AccountType } from "shared/constants/enums";
import {
    CompanyUserPermissions,
    InternalUserPermissions,
    viewCustomerUserInternalPermissions,
    viewDepositsPermissions,
} from "shared/constants/permissions";
import lazyWithRetry from "shared/helpers/lazyWithRetry";
import routeNames from "shared/routes/constants/routeNames";
import RouteValue from "shared/routes/models/routesValue";

import { LayoutType } from "app/layout/store";

const AccessErrorPage = lazyWithRetry(() => import("app/errors/AccessErrorPage"));
const Account = lazyWithRetry(() => import("app/spaceStone/account"));
const AddProperty = lazyWithRetry(() => import("app/properties/add"));
const AddTenancy = lazyWithRetry(() => import("app/tenancies/add"));
const AdminAddProperty = lazyWithRetry(() => import("app/properties/admin/add"));
const AdminBusinesses = lazyWithRetry(() => import("app/administration/business"));
const AdminBusinessesProfile = lazyWithRetry(() => import("app/administration/business/profile"));
const AdminBusinessMerge = lazyWithRetry(() => import("app/administration/business/mergeCompany"));
const AdminFinance = lazyWithRetry(() => import("app/administration/finance"));
const AdminOfficeProfile = lazyWithRetry(() => import("app/administration/offices/view"));
const AdminProperties = lazyWithRetry(() => import("app/administration/properties"));
const AdminSettings = lazyWithRetry(() => import("app/administration/settings"));
const AdminUserCreate = lazyWithRetry(() => import("app/administration/users/create"));
const AdminInternalUserProfile = lazyWithRetry(() => import("app/administration/settings/internalUserProfile"));
const AdminPartnerProfile = lazyWithRetry(() => import("app/administration/settings/partnersTab/partnerProfile"));
const AdminUsersList = lazyWithRetry(() => import("app/administration/users/list"));
const AdminUserProfile = lazyWithRetry(() => import("app/administration/users/profile"));
const AdminLinkAccount = lazyWithRetry(() => import("app/administration/users/linkAccount"));
const AdminMergeAccount = lazyWithRetry(() => import("app/administration/users/mergeAccount"));
const BasketCheckout = lazyWithRetry(() => import("app/checkout/view"));
const ChangeEmailConfirmation = lazyWithRetry(() => import("components/userProfile/changeEmailConfirmation"));
const CompanyDetails = lazyWithRetry(() => import("app/spaceStone/companyDetails"));
const CompanyOffice = lazyWithRetry(() => import("app/spaceStone/companyOffice"));
const DepositRedirector = lazyWithRetry(() => import("app/deposits/view/redirector"));
const UnallocatedFundsRefund = lazyWithRetry(() => import("app/deposits/listView/unallocatedFundsTab/refund"));
const UnallocatedFundsRefundForBusinessTab = lazyWithRetry(
    () => import("app/administration/business/profile/tabs/unallocatedFundsTab/refund")
);
const UnallocatedFundsRefundForUsersTab = lazyWithRetry(
    () => import("app/administration/users/profile/tabs/unallocatedFundsTab/refund")
);
const Deposits = lazyWithRetry(() => import("app/deposits/listView"));
const ExpiredChangeEmailLink = lazyWithRetry(() => import("app/errors/ExpiredLinkErrorPage/expiredChangeEmailLink"));
const ExpiredInviteLink = lazyWithRetry(() => import("app/errors/ExpiredLinkErrorPage/expiredInviteLink"));
const Home = lazyWithRetry(() => import("app/dashboard"));
const InternalErrorPage = lazyWithRetry(() => import("app/errors/InternalErrorPage"));
const Insurance = lazyWithRetry(() => import("app/insurance"));
const InsuranceGetQuote = lazyWithRetry(() => import("components/insurance/getQuote"));
const Landlord = lazyWithRetry(() => import("app/landlords/view"));
const Landlords = lazyWithRetry(() => import("app/landlords/listView"));
const Login = lazyWithRetry(() => import("app/spaceStone/signIn"));
const LoginCallback = lazyWithRetry(() => import("app/spaceStone/signIn/callback"));
const MissingDetails = lazyWithRetry(() => import("app/spaceStone/missingDetails"));
const MyProfile = lazyWithRetry(() => import("app/myProfile"));
const OfficeRouter = lazyWithRetry(() => import("app/spaceStone/companyOffice/OfficeRouter"));
const PageNotFoundErrorLink = lazyWithRetry(() => import("app/errors/NotFoundErrorPage"));
const LoaderTab = lazyWithRetry(() => import("components/loaderTab"));
const LogoutSwitch = lazyWithRetry(() => import("app/layout/menu/profile/logoutSwitch"));
const Properties = lazyWithRetry(() => import("app/properties/listView"));
const Property = lazyWithRetry(() => import("app/properties/view"));
const ReleaseRequestAllEvidence = lazyWithRetry(() => import("app/timeStone/releaseRequests/view/allEvidence"));
const ReleaseRequestRouter = lazyWithRetry(() => import("app/timeStone/releaseRequestRouter"));
const ResolutionPanel = lazyWithRetry(() => import("app/resolutionPanel"));
const RevokedInviteLink = lazyWithRetry(() => import("app/errors/ExpiredLinkErrorPage/revokedInviteLink"));
const SignUp = lazyWithRetry(() => import("app/spaceStone/signUp"));
const SignUpContinue = lazyWithRetry(() => import("app/spaceStone/signUp/steps/continueSignUp"));
const SignOut = lazyWithRetry(() => import("app/spaceStone/signOut"));
const SignedOut = lazyWithRetry(() => import("app/spaceStone/signedOut"));
const Staff = lazyWithRetry(() => import("app/spaceStone/staff"));
const StaffUser = lazyWithRetry(() => import("app/spaceStone/staff/user"));
const StartClaim = lazyWithRetry(() => import("app/claims/start"));
const StartEndOfTenancy = lazyWithRetry(() => import("app/endOfTenancies/start"));
const StartReleaseRequest = lazyWithRetry(() => import("app/timeStone/releaseRequests/start"));
const StartSingleRelease = lazyWithRetry(() => import("app/timeStone/singleReleases/start"));
const Tenancies = lazyWithRetry(() => import("app/tenancies/listView"));
const Tenancy = lazyWithRetry(() => import("app/tenancies/view"));
const TenantBilling = lazyWithRetry(() => import("app/tenant/billing"));
const TenantMyHome = lazyWithRetry(() => import("app/tenant/myHome"));
const TenantsChange = lazyWithRetry(() => import("app/tenancies/change"));
const Activity = lazyWithRetry(() => import("app/activity"));
const BulkReleaseRequestConfirmation = lazyWithRetry(
    () => import("app/timeStone/releaseRequests/bulkInitiationConfirm")
);
const Transfer = lazyWithRetry(() => import("app/transfers/add"));
const PaymentHistory = lazyWithRetry(() => import("app/paymentHistory"));
const InitiateMidTermRelease = lazyWithRetry(() => import("app/midTermReleases/start"));

const routes: RouteValue[] = [
    {
        name: routeNames.DASHBOARD,
        path: routeNames.DASHBOARD,
        isPrivate: true,
        component: Home,
        onlyForAccountTypes: [
            AccountType.Admin,
            AccountType.Agent,
            AccountType.CorporateLandlord,
            AccountType.PrivateLandlord,
        ],
    },
    {
        name: routeNames.MISSING_DETAILS,
        path: routeNames.MISSING_DETAILS,
        isPrivate: true,
        component: MissingDetails,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.LOGIN.CALLBACK,
        path: routeNames.LOGIN.CALLBACK,
        isPrivate: false,
        exact: true,
        component: LoginCallback,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.LOGIN.ROOT,
        path: routeNames.LOGIN.ROOT,
        isPrivate: false,
        exact: true,
        component: Login,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.LOGOUT.SWITCH,
        path: routeNames.LOGOUT.SWITCH,
        isPrivate: true,
        component: LogoutSwitch,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.LOGOUT.ROOT,
        path: routeNames.LOGOUT.ROOT,
        isPrivate: true,
        component: SignOut,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.LOGGED_OUT,
        path: routeNames.LOGGED_OUT,
        isPrivate: true,
        component: SignedOut,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.SIGN_UP.SIGN_UP,
        path: routeNames.SIGN_UP.SIGN_UP,
        isPrivate: false,
        component: SignUp,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.SIGN_UP.SIGN_UP_LINK,
        path: routeNames.SIGN_UP.SIGN_UP_LINK,
        isPrivate: false,
        component: SignUpContinue,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.ERRORS.NOT_FOUND,
        path: routeNames.ERRORS.NOT_FOUND,
        isPrivate: false,
        component: PageNotFoundErrorLink,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.ERRORS.FORBIDDEN,
        path: routeNames.ERRORS.FORBIDDEN,
        isPrivate: false,
        component: AccessErrorPage,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.ERRORS.INTERNAL_ERROR,
        path: routeNames.ERRORS.INTERNAL_ERROR,
        isPrivate: false,
        component: InternalErrorPage,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.REDIRECTS.RELEASE_REQUEST.ALL_EVIDENCE,
        path: routeNames.REDIRECTS.RELEASE_REQUEST.ALL_EVIDENCE,
        isPrivate: true,
        layout: LayoutType.FullWidthLayout,
        component: ReleaseRequestAllEvidence,
        exact: true,
    },
    {
        name: routeNames.REDIRECTS.RELEASE_REQUEST.ROOT,
        path: routeNames.REDIRECTS.RELEASE_REQUEST.ROOT,
        isPrivate: true,
        component: ReleaseRequestRouter,
        exact: true,
    },
    {
        name: routeNames.RESOLUTION_PANEL.ROOT,
        path: routeNames.RESOLUTION_PANEL.ROOT,
        isPrivate: true,
        component: ResolutionPanel,
        exact: false,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: ResolutionPanel,
                permission: InternalUserPermissions.ProcessEndOfCustodialProtection,
            },
            [AccountType.CorporateLandlord]: {
                component: ResolutionPanel,
                permission: CompanyUserPermissions.ProcessCompanyEndOfCustodialProtection,
            },
            [AccountType.Agent]: {
                component: ResolutionPanel,
                permission: CompanyUserPermissions.ProcessCompanyEndOfCustodialProtection,
            },
        },
    },
    {
        name: routeNames.MY_PROFILE.ROOT,
        path: routeNames.MY_PROFILE.ROOT,
        isPrivate: true,
        component: MyProfile,
        exact: false,
    },
    {
        name: routeNames.CHANGE_EMAIL,
        path: routeNames.CHANGE_EMAIL,
        isPrivate: false,
        component: ChangeEmailConfirmation,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.ERRORS.EXPIRED_CHANGE_EMAIL_LINK,
        path: routeNames.ERRORS.EXPIRED_CHANGE_EMAIL_LINK,
        isPrivate: false,
        component: ExpiredChangeEmailLink,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.COMPANY.OFFICE.REDIRECT,
        path: routeNames.COMPANY.OFFICE.REDIRECT,
        isPrivate: true,
        component: OfficeRouter,
    },
    {
        name: routeNames.COMPANY.OFFICE.ROOT,
        path: routeNames.COMPANY.OFFICE.ROOT,
        isPrivate: true,
        component: CompanyOffice,
    },
    {
        name: routeNames.COMPANY.ROOT,
        path: routeNames.COMPANY.ROOT,
        isPrivate: true,
        component: CompanyDetails,
        onlyForAccountTypes: [AccountType.Agent, AccountType.CorporateLandlord],
    },
    {
        name: routeNames.ADMIN.USERS.ROOT,
        path: routeNames.ADMIN.USERS.ROOT,
        exact: true,
        isPrivate: true,
        component: AdminUsersList,
        onlyForAccountTypes: [AccountType.Admin],
        ...viewCustomerUserInternalPermissions,
    },
    {
        name: routeNames.ADMIN.USERS.CREATE,
        path: routeNames.ADMIN.USERS.CREATE,
        exact: true,
        isPrivate: true,
        component: AdminUserCreate,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [InternalUserPermissions.CreateNewCustomerUserUsers],
    },
    {
        name: routeNames.ADMIN.USER.UNALLOCATED_FUNDS.REFUND,
        path: routeNames.ADMIN.USER.UNALLOCATED_FUNDS.REFUND,
        isPrivate: true,
        exact: true,
        component: UnallocatedFundsRefundForUsersTab,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.USER.UNALLOCATED_FUNDS.TRANSFER,
        path: routeNames.ADMIN.USER.UNALLOCATED_FUNDS.TRANSFER,
        isPrivate: true,
        exact: true,
        component: Transfer,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.USER.MERGE_ACCOUNT,
        path: routeNames.ADMIN.USER.MERGE_ACCOUNT,
        isPrivate: true,
        component: AdminMergeAccount,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [InternalUserPermissions.LinkAndMergeAccountUsers],
    },
    {
        name: routeNames.ADMIN.USER.LINK_ACCOUNT,
        path: routeNames.ADMIN.USER.LINK_ACCOUNT,
        isPrivate: true,
        component: AdminLinkAccount,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [InternalUserPermissions.LinkAndMergeAccountUsers],
    },
    {
        name: routeNames.ADMIN.USER.ROOT,
        path: routeNames.ADMIN.USER.ROOT,
        isPrivate: true,
        component: AdminUserProfile,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.FINANCE.ROOT,
        path: routeNames.ADMIN.FINANCE.ROOT,
        isPrivate: true,
        component: AdminFinance,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [InternalUserPermissions.ViewPaymentHistory],
    },
    {
        name: routeNames.REDIRECTS.FILE_LOADING.ROOT,
        path: routeNames.REDIRECTS.FILE_LOADING.ROOT,
        isPrivate: true,
        component: LoaderTab,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.ADMIN.SETTINGS.ADMIN.ROOT,
        path: routeNames.ADMIN.SETTINGS.ADMIN.ROOT,
        isPrivate: true,
        component: AdminInternalUserProfile,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.SETTINGS.PARTNER.ROOT,
        path: routeNames.ADMIN.SETTINGS.PARTNER.ROOT,
        isPrivate: true,
        component: AdminPartnerProfile,
        onlyForAccountTypes: [AccountType.Admin],
        permission: InternalUserPermissions.ViewPartners,
    },
    {
        name: routeNames.ADMIN.SETTINGS.ROOT,
        path: routeNames.ADMIN.SETTINGS.ROOT,
        isPrivate: true,
        isTolerantPermissions: true,
        component: AdminSettings,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [
            InternalUserPermissions.ViewPortalSettings,
            InternalUserPermissions.ViewInternalUsersAdminUsers,
            InternalUserPermissions.ViewRolesRolesAndPermissions,
            InternalUserPermissions.ViewSchemeConfigurations,
            InternalUserPermissions.ViewPartners,
        ],
    },
    {
        name: routeNames.ADMIN.BUSINESSES.OFFICE_PROFILE.ROOT,
        path: routeNames.ADMIN.BUSINESSES.OFFICE_PROFILE.ROOT,
        isPrivate: true,
        component: AdminOfficeProfile,
        isTolerantPermissions: true,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [
            InternalUserPermissions.ViewCompaniesBusinesses,
            InternalUserPermissions.EditCompanyDetailsBusinesses,
        ],
        paramAsKey: "companyId",
    },
    {
        name: routeNames.ADMIN.BUSINESSES.PROFILE.UNALLOCATED_FUNDS.TRANSFER,
        path: routeNames.ADMIN.BUSINESSES.PROFILE.UNALLOCATED_FUNDS.TRANSFER,
        isPrivate: true,
        exact: true,
        component: Transfer,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.BUSINESSES.PROFILE.UNALLOCATED_FUNDS.REFUND,
        path: routeNames.ADMIN.BUSINESSES.PROFILE.UNALLOCATED_FUNDS.REFUND,
        isPrivate: true,
        exact: true,
        component: UnallocatedFundsRefundForBusinessTab,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.ADMIN.BUSINESSES.MERGE_ACCOUNT,
        path: routeNames.ADMIN.BUSINESSES.MERGE_ACCOUNT,
        isPrivate: true,
        component: AdminBusinessMerge,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [InternalUserPermissions.LinkAndMergeAccountUsers],
    },
    {
        name: routeNames.ADMIN.BUSINESSES.PROFILE.ROOT,
        path: routeNames.ADMIN.BUSINESSES.PROFILE.ROOT,
        isPrivate: true,
        component: AdminBusinessesProfile,
        isTolerantPermissions: true,
        onlyForAccountTypes: [AccountType.Admin],
        permission: [
            InternalUserPermissions.ViewCompaniesBusinesses,
            InternalUserPermissions.EditCompanyDetailsBusinesses,
        ],
    },
    {
        name: routeNames.ADMIN.BUSINESSES.ROOT,
        path: routeNames.ADMIN.BUSINESSES.ROOT,
        isPrivate: true,
        component: AdminBusinesses,
        onlyForAccountTypes: [AccountType.Admin],
        isTolerantPermissions: true,
        permission: [
            InternalUserPermissions.ViewCompaniesBusinesses,
            InternalUserPermissions.EditCompanyDetailsBusinesses,
        ],
    },
    {
        name: routeNames.STAFF.USER,
        path: routeNames.STAFF.USER,
        isPrivate: true,
        component: StaffUser,
        exact: true,
    },
    {
        name: routeNames.STAFF.ROOT,
        path: routeNames.STAFF.ROOT,
        isPrivate: true,
        component: Staff,
        onlyForAccountTypes: [AccountType.Agent, AccountType.CorporateLandlord],
        permission: [CompanyUserPermissions.ViewUsersStaff, CompanyUserPermissions.EditUsersStaff],
        isTolerantPermissions: true,
    },
    {
        name: routeNames.PROPERTIES.CREATE,
        path: routeNames.PROPERTIES.CREATE,
        isPrivate: true,
        component: AddProperty,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: AdminAddProperty,
                permission: InternalUserPermissions.ManageProperties,
            },
            [AccountType.CorporateLandlord]: {
                component: AddProperty,
                permission: CompanyUserPermissions.ManageProperties,
            },
            [AccountType.Agent]: {
                component: AddProperty,
                permission: CompanyUserPermissions.ManageProperties,
            },
        },
    },
    {
        name: routeNames.PROPERTY.DEPOSIT.TRANSFER,
        path: routeNames.PROPERTY.DEPOSIT.TRANSFER,
        isPrivate: true,
        exact: true,
        component: Transfer,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.PROPERTY.DEPOSIT.MID_TERM_RELEASE,
        path: routeNames.PROPERTY.DEPOSIT.MID_TERM_RELEASE,
        isPrivate: true,
        exact: true,
        component: InitiateMidTermRelease,
        onlyForAccountTypes: [AccountType.Admin],
    },
    {
        name: routeNames.REDIRECTS.DEPOSIT.ROOT,
        path: routeNames.REDIRECTS.DEPOSIT.ROOT,
        isPrivate: true,
        exact: true,
        component: DepositRedirector,
    },
    {
        name: routeNames.PROPERTY.CLAIMS.CREATE,
        path: routeNames.PROPERTY.CLAIMS.CREATE,
        isPrivate: true,
        component: StartClaim,
    },
    {
        name: routeNames.PROPERTY.END_OF_TENANCY.CREATE,
        path: routeNames.PROPERTY.END_OF_TENANCY.CREATE,
        isPrivate: true,
        exact: true,
        onlyForAccountTypes: [
            AccountType.Agent,
            AccountType.PrivateLandlord,
            AccountType.CorporateLandlord,
            AccountType.Admin,
        ],
        component: StartEndOfTenancy,
    },
    {
        name: routeNames.TENANT_HOME.END_OF_TENANCY.CREATE,
        path: routeNames.TENANT_HOME.END_OF_TENANCY.CREATE,
        isPrivate: true,
        exact: true,
        component: StartEndOfTenancy,
        onlyForAccountTypes: [AccountType.Tenant],
    },
    {
        name: routeNames.PROPERTY.TENANCY.CHANGE,
        path: routeNames.PROPERTY.TENANCY.CHANGE,
        isPrivate: true,
        component: TenantsChange,
        onlyForAccountTypes: [AccountType.Admin, AccountType.Tenant],
    },
    {
        name: routeNames.PROPERTY.TENANCY.CREATE,
        path: routeNames.PROPERTY.TENANCY.CREATE,
        isPrivate: true,
        component: AddTenancy,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: AddTenancy,
                permission: InternalUserPermissions.ManageTenancies,
            },
            [AccountType.CorporateLandlord]: {
                component: AddTenancy,
                permission: CompanyUserPermissions.ManageTenancies,
            },
            [AccountType.Agent]: {
                component: AddTenancy,
                permission: CompanyUserPermissions.ManageTenancies,
            },
        },
    },
    {
        name: routeNames.PROPERTY.RELEASE_REQUEST.CREATE,
        path: routeNames.PROPERTY.RELEASE_REQUEST.CREATE,
        isPrivate: true,
        exact: true,
        component: StartReleaseRequest,
        onlyForAccountTypes: [
            AccountType.Agent,
            AccountType.CorporateLandlord,
            AccountType.PrivateLandlord,
            AccountType.Admin,
        ],
    },
    {
        name: routeNames.TENANT_HOME.RELEASE_REQUEST.CREATE,
        path: routeNames.TENANT_HOME.RELEASE_REQUEST.CREATE,
        isPrivate: true,
        exact: true,
        component: StartReleaseRequest,
        onlyForAccountTypes: [AccountType.Tenant],
    },
    {
        name: routeNames.PROPERTY.RELEASE_REQUEST.SINGLE_RELEASE.CREATE,
        path: routeNames.PROPERTY.RELEASE_REQUEST.SINGLE_RELEASE.CREATE,
        isPrivate: true,
        exact: true,
        component: StartSingleRelease,
    },
    {
        name: routeNames.PROPERTY.RELEASE_REQUEST.SINGLE_RELEASE.UPDATE,
        path: routeNames.PROPERTY.RELEASE_REQUEST.SINGLE_RELEASE.UPDATE,
        isPrivate: true,
        exact: true,
        component: StartSingleRelease,
    },
    {
        name: routeNames.PROPERTY.INSURANCE.GET_QUOTE,
        path: routeNames.PROPERTY.INSURANCE.GET_QUOTE,
        isPrivate: true,
        component: InsuranceGetQuote,
        onlyForAccountTypes: [AccountType.PrivateLandlord, AccountType.CorporateLandlord],
    },
    {
        name: routeNames.PROPERTY.ROOT,
        path: routeNames.PROPERTY.ROOT,
        isPrivate: true,
        component: Property,
    },
    {
        name: routeNames.PROPERTIES.ROOT,
        path: routeNames.PROPERTIES.ROOT,
        isPrivate: true,
        component: Properties,
        onlyForAccountTypes: [
            AccountType.Agent,
            AccountType.PrivateLandlord,
            AccountType.CorporateLandlord,
            AccountType.Admin,
        ],
        routeByAccountType: {
            [AccountType.Admin]: {
                component: AdminProperties,
                permission: [InternalUserPermissions.ManageProperties, InternalUserPermissions.ViewProperties],
                isTolerantPermissions: true,
            },
            [AccountType.CorporateLandlord]: {
                component: Properties,
                permission: [CompanyUserPermissions.ManageProperties, CompanyUserPermissions.ViewProperties],
                isTolerantPermissions: true,
            },
            [AccountType.Agent]: {
                component: Properties,
                permission: [CompanyUserPermissions.ManageProperties, CompanyUserPermissions.ViewProperties],
                isTolerantPermissions: true,
            },
        },
    },
    {
        name: routeNames.INVITATIONS.REVOKE_INVITE,
        path: routeNames.INVITATIONS.REVOKE_INVITE,
        isPrivate: false,
        component: RevokedInviteLink,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.INVITATIONS.EXPIRED_INVITE,
        path: routeNames.INVITATIONS.EXPIRED_INVITE,
        isPrivate: false,
        component: ExpiredInviteLink,
        layout: LayoutType.FullWidthLayout,
    },
    {
        name: routeNames.INSURANCE.LANDLORD_INSURANCE_GET_QUOTE,
        path: routeNames.INSURANCE.LANDLORD_INSURANCE_GET_QUOTE,
        isPrivate: true,
        component: InsuranceGetQuote,
        onlyForAccountTypes: [AccountType.PrivateLandlord, AccountType.CorporateLandlord],
    },
    {
        name: routeNames.INSURANCE.ROOT,
        path: routeNames.INSURANCE.ROOT,
        isPrivate: true,
        component: Insurance,
        onlyForAccountTypes: [AccountType.PrivateLandlord, AccountType.CorporateLandlord, AccountType.Agent],
    },
    {
        name: routeNames.TENANT_HOME.ROOT,
        path: routeNames.TENANT_HOME.ROOT,
        isPrivate: true,
        exact: false,
        component: TenantMyHome,
        onlyForAccountTypes: [AccountType.Tenant],
    },
    {
        name: routeNames.TENANT_BILLING.ROOT,
        path: routeNames.TENANT_BILLING.ROOT,
        isPrivate: true,
        component: TenantBilling,
    },
    {
        name: routeNames.ACTIVITY.ROOT,
        path: routeNames.ACTIVITY.ROOT,
        isPrivate: true,
        component: Activity,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: Activity,
                permission: InternalUserPermissions.ViewActivity,
            },
            [AccountType.CorporateLandlord]: {
                component: Activity,
                permission: CompanyUserPermissions.ViewActivity,
            },
            [AccountType.Agent]: {
                component: Activity,
                permission: CompanyUserPermissions.ViewActivity,
            },
        },
    },
    {
        name: routeNames.TENANCIES.ROOT,
        path: routeNames.TENANCIES.ROOT,
        isPrivate: true,
        component: Tenancies,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: Tenancies,
                permission: InternalUserPermissions.ViewTenancies,
            },
            [AccountType.CorporateLandlord]: {
                component: Tenancies,
                permission: CompanyUserPermissions.ViewTenancies,
            },
            [AccountType.Agent]: {
                component: Tenancies,
                permission: CompanyUserPermissions.ViewTenancies,
            },
        },
    },
    {
        name: routeNames.REDIRECTS.TENANCY.ROOT,
        path: routeNames.REDIRECTS.TENANCY.ROOT,
        isPrivate: true,
        exact: true,
        component: Tenancy,
    },
    {
        name: routeNames.DEPOSITS.UNALLOCATED_FUNDS.REFUND,
        path: routeNames.DEPOSITS.UNALLOCATED_FUNDS.REFUND,
        isPrivate: true,
        exact: true,
        component: UnallocatedFundsRefund,
        onlyForAccountTypes: [AccountType.PrivateLandlord, AccountType.CorporateLandlord, AccountType.Agent],
    },
    {
        name: routeNames.DEPOSITS.BASKET_CHECKOUT,
        path: routeNames.DEPOSITS.BASKET_CHECKOUT,
        isPrivate: true,
        exact: true,
        component: BasketCheckout,
    },
    {
        name: routeNames.REDIRECTS.ACCOUNT.ROOT,
        path: routeNames.REDIRECTS.ACCOUNT.ROOT,
        isPrivate: true,
        exact: true,
        component: Account,
    },
    {
        name: routeNames.DEPOSITS.BULK_RELEASE_REQUEST_CONFIRMATION,
        path: routeNames.DEPOSITS.BULK_RELEASE_REQUEST_CONFIRMATION,
        isPrivate: true,
        exact: true,
        component: BulkReleaseRequestConfirmation,
    },
    {
        name: routeNames.DEPOSITS.ROOT,
        path: routeNames.DEPOSITS.ROOT,
        isPrivate: true,
        exact: false,
        component: Deposits,
        routeByAccountType: {
            [AccountType.Admin]: {
                component: Deposits,
                ...viewDepositsPermissions,
            },
            [AccountType.CorporateLandlord]: {
                component: Deposits,
                ...viewDepositsPermissions,
            },
            [AccountType.Agent]: {
                component: Deposits,
                ...viewDepositsPermissions,
            },
        },
    },
    {
        name: routeNames.LANDLORDS.ROOT,
        path: routeNames.LANDLORDS.ROOT,
        isPrivate: true,
        exact: true,
        component: Landlords,
        onlyForAccountTypes: [AccountType.Agent],
    },
    {
        name: routeNames.LANDLORD.ROOT,
        path: routeNames.LANDLORD.ROOT,
        isPrivate: true,
        exact: true,
        component: Landlord,
        onlyForAccountTypes: [AccountType.Agent],
    },
    {
        name: routeNames.PAYMENT_HISTORY.ROOT,
        path: routeNames.PAYMENT_HISTORY.ROOT,
        isPrivate: true,
        exact: true,
        component: PaymentHistory,
    },
];

export default routes;
