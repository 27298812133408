/**
 * Summary: Merge accounts.
 *
 * Tags: Accounts
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_ADMIN_ACCOUNT_MERGE_URL = (accountId: number): string =>
    `api/v1/admin/accounts/${accountId}/merge`;

export const apiPostAdminAccountMerge = async (
    accountId: number,
    request?: ApiPostAdminAccountMergeRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostAdminAccountMergeResponse> => {
    const result = await httpPost<ApiPostAdminAccountMergeResponse, ApiPostAdminAccountMergeRequest>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_POST_ADMIN_ACCOUNT_MERGE_URL(accountId),
        request,
        config
    );
    return result.data;
};

/**
 * Description: Request body with details of merging accounts.
 */
export type ApiPostAdminAccountMergeRequest = MergeAccountRequest;

/**
 * Description: Success
 */
export type ApiPostAdminAccountMergeResponse = void;

interface MergeAccountRequest {
    mergingAccountId: number; // Merging account ID. Example: 123
    primaryUserId: number; // Primary user ID. Example: 123
    mergingUserId: number; // Merging user ID. Example: 123
}
