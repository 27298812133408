import { countBy, filter, orderBy } from "lodash";

import { GroupedAccountsModel, ProfileShortAccountDetails } from "components/userProfile/interfaces";
import { AccountType } from "shared/constants/enums";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import { formatMinTwoDigitNumber } from "shared/utils/number.utils";

export const formatProfileAccountType = (
    account: ProfileShortAccountDetails,
    hasMultipleAccountsOfSameType: boolean,
    localize: LocalizeFunction
) => {
    const localizedAccountType = localize(account.accountType.text);

    return hasMultipleAccountsOfSameType
        ? `${localizedAccountType} ${formatMinTwoDigitNumber(account.accountIndex)}`
        : localizedAccountType;
};

export const groupAccounts = (
    localize: LocalizeFunction,
    accounts: ProfileShortAccountDetails[] = []
): GroupedAccountsModel => {
    const counts = countBy(accounts, it => it.accountType.id);
    const duplicatedAccountTypes = new Set<number>(
        filter(
            accounts.map(it => it.accountType.id),
            it => counts[it] > 1
        )
    );

    const sortedAccounts = orderBy(accounts, acc => acc.accountIndex);

    return sortedAccounts.reduce((acc, current) => {
        const currentAccountTypeId: AccountType = current.accountType.id;

        if (!acc[currentAccountTypeId]) {
            acc[currentAccountTypeId] = [];
        }

        acc[currentAccountTypeId].push({
            id: current.accountId,
            text: formatProfileAccountType(current, duplicatedAccountTypes.has(current.accountType.id), localize),
            status: current.accountUserStatus,
        });

        return acc;
    }, {} as GroupedAccountsModel);
};
