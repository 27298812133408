export enum LocalizationId {
    PageHeader = "insurance-landlord-tab.page_header",
    NotificationText = "insurance-landlord-tab.notification_text",
    TotalLandlordLabel = "insurance-landlord-tab.total_landlord_label",
    SpecialistInsuranceItemText = "insurance-landlord-tab.specialist_insurance_list_item_text",
    NoChangePriceItemText = "insurance-landlord-tab.no_change_price_list_item_text",
    SameNameItemText = "insurance-landlord-tab.same_name_list_item_text",
    CustomerRecommendationItemText = "insurance-landlord-tab.customer_recommendation_list_item_text",
    GetQuoteButton = "insurance-landlord-tab.get_quote_button",
    RedirectLink = "insurance-landlord-tab.redirect_link",
    InsuranceImage = "insurance-landlord-tab.landlord_insurance_image",
    ConcurrencyErrorText = "shared-messages.toast.concurrency_session",
}
