import { useEffect } from "react";

import { useRequestContext } from "shared/api/RequestProvider";
import { apiGetInsuranceIsInsuranceEnabled } from "shared/api/swagger/spaceStone/insurance/apiGetInsuranceIsInsuranceEnabled";
import { FETCH_IS_INSURANCE_ENABLED } from "shared/constants/requestActionConstants";
import { useHistory } from "shared/hooks/useHistory";
import routeNames from "shared/routes/constants/routeNames";

export const useControlInsuranceAccess = (): void => {
    const { executeRequest } = useRequestContext();
    const { historyReplace } = useHistory();

    useEffect(() => {
        const request = async () => {
            const { isInsuranceEnabledForCurrentUser } = await executeRequest(
                apiGetInsuranceIsInsuranceEnabled,
                FETCH_IS_INSURANCE_ENABLED,
                []
            );

            if (!isInsuranceEnabledForCurrentUser) {
                historyReplace(routeNames.ERRORS.NOT_FOUND);
            }
        };

        request();
    }, [executeRequest, historyReplace]);
};
