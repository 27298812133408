import { DEFAULT_COUNTRY, NO_VALUE_PLACEHOLDER } from "shared/constants/appConstants";
import { AddressFormModel, AddressModel, PropertyAddressModel } from "shared/interfaces/address";

export default function formatAddress(address: Partial<AddressModel>): string {
    const { addressLine2, addressLine1, city, postcode, country, region } = address;

    const orderedAddressArray = [addressLine1, addressLine2, city, region?.text, postcode, country?.text];
    const filteredAddressArray = orderedAddressArray.filter(Boolean);

    return filteredAddressArray.length > 0 ? filteredAddressArray.join(", ") : NO_VALUE_PLACEHOLDER;
}

export function formatAddressPropertyMode(address: Partial<AddressModel>): string {
    const { room, addressLine2, addressLine1, city, postcode, region } = address;

    const orderedAddressArray = [room, addressLine1, addressLine2, city, region?.text, postcode];
    const filteredAddressArray = orderedAddressArray.filter(Boolean);

    return filteredAddressArray.length > 0 ? filteredAddressArray.join(", ") : NO_VALUE_PLACEHOLDER;
}

export const convertToPropertyAddressModel = (address: AddressFormModel): PropertyAddressModel => {
    const {
        addressLine1,
        addressLine2,
        city,
        postcode,
        region,
        country,
        room,
        latitude,
        longitude,
        uniquePropertyReferenceNumber,
        isAddressSetManually,
    } = address;

    return {
        addressLine1: addressLine1?.addressLine1 ?? "",
        addressLine2,
        city,
        postcode,
        region,
        country: country ?? DEFAULT_COUNTRY,
        room,
        latitude: !!latitude ? Number(latitude) : undefined,
        longitude: !!longitude ? Number(longitude) : undefined,
        uniquePropertyReferenceNumber,
        isAddressSetManually,
    };
};

export const emptyAddressModel: AddressModel = {
    addressLine1: "",
    addressLine2: "",
    postcode: "",
    city: "",
    country: DEFAULT_COUNTRY,
    region: undefined,
    latitude: undefined,
    longitude: undefined,
    uniquePropertyReferenceNumber: "",
    room: "",
    isAddressSetManually: true,
};
