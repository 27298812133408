import { ApiGetReleaseRequestDocumentsResponse } from "shared/api/swagger/timeStone/releaseRequests/apiGetReleaseRequestDocuments";
import { FileEntity, FileLightEntity, SaveOrUpdateFileModel, UploadedFileData } from "shared/models/files";

type FileInfoDto = Unpacked<ApiGetReleaseRequestDocumentsResponse["checkInReports"]>;

export const mapFileInfoDtoToFileData = ({ originalFileName, ...rest }: FileInfoDto): FileEntity => {
    return {
        ...rest,
        originalFileName,
        fileName: originalFileName,
        blobName: "",
        contentType: "",
    };
};

export const mapFileInfoDtoToUploadedFile = ({
    fileId,
    originalFileName,
    fileSizeInBytes,
}: FileInfoDto): UploadedFileData => {
    return {
        fileId,
        fileName: originalFileName,
        fileSizeInBytes: fileSizeInBytes,
        originalFileName: originalFileName,
        loadedBytes: fileSizeInBytes,
        blobName: "",
        contentType: "",
    };
};

export const mapFileToUploadedFile = (fileData: FileEntity): UploadedFileData => {
    return {
        ...fileData,
        loadedBytes: fileData.fileSizeInBytes,
    };
};

export const mapFileToReadonlyUploadedFile = (fileData: FileEntity): UploadedFileData => {
    return {
        ...fileData,
        loadedBytes: fileData.fileSizeInBytes,
        isReadOnly: true,
    };
};

export const mapFileEntityToSaveOrUpdateFileModel = ({
    fileId,
    blobName,
    originalFileName,
}: FileEntity): SaveOrUpdateFileModel => {
    return {
        fileId: fileId > 0 ? fileId : undefined,
        blobName,
        originalFileName,
    };
};

export const mapFileLightEntityToSaveOrUpdateFileModel = ({ fileId }: FileLightEntity): SaveOrUpdateFileModel => {
    return {
        fileId: fileId,
    };
};

export const mapFileLightEntityToUploadedFile = (file: FileLightEntity): UploadedFileData => {
    return {
        fileSizeInBytes: 0,
        originalFileName: "",
        fileName: "",
        blobName: "",
        contentType: "",
        ...file,
    };
};
